window.addEventListener('DOMContentLoaded', () => {
  let tooltipVisible = false;
  const notificationBox = document.getElementById('notification-box');
  const overlay = document.querySelector('.overlay');
  const notif_tooltip = document.getElementById('notifications-tooltip');
  const tooltip_boxes = document.querySelectorAll('.tooltip-box');
  
  if (notificationBox) {
    notificationBox.addEventListener('click', function(event) {
      if ((!tooltipVisible) && (notificationBox.classList.contains("active-notifications"))) {
        openNotif(event);
      } else {
        closeNotif(event);
      }
    });
  }

  document.addEventListener('click', function(event) {
    closeNotif(event);
  })

  function openNotif(event) {
    event.stopPropagation();
    notif_tooltip.style.visibility = 'visible';
    notif_tooltip.style.opacity = '1';
    overlay.style.visibility = 'visible';
    overlay.style.zIndex = '1';
    tooltip_boxes.forEach(tooltip_box => {
      tooltip_box.style.pointerEvents = "none";
    });
    tooltipVisible = true;
  }

  function closeNotif(event) {
    event.stopPropagation();
      if (tooltipVisible) {
        overlay.style.visibility = 'hidden';
        notif_tooltip.style.visibility = 'hidden';
        notif_tooltip.style.opacity = '0';
        tooltip_boxes.forEach(tooltip_box => {
          tooltip_box.style.pointerEvents = "auto";
        });
        overlay.style.zIndex = '4';
        tooltipVisible = false;
      }
  }
});