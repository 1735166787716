window.addEventListener('DOMContentLoaded', () => {
  const currentUserHolidaysPage = document.getElementById('current_user_holidays');
  const overlay = document.querySelector(".overlay");
  const rtt_warning = document.getElementById("rtt-warning");
  const cp_warning = document.getElementById("cp-warning");
  const main_wrapper = document.querySelectorAll('.main-wrapper');
  const tooltip_boxes = document.querySelectorAll('.tooltip-box');
  const notificationBox = document.getElementById('notification-box');

  if(currentUserHolidaysPage) {
    if (document.getElementById("rtt")?.classList.contains("warning")) {
      openModal(rtt_warning, overlay, tooltip_boxes, notificationBox)
    };
    
    if (document.getElementById("cp-en-cours")?.classList.contains("warning")) {
      openModal(cp_warning, overlay, tooltip_boxes, notificationBox);
  
    };
    
    document.addEventListener('click', function(event) {
      if (event.target.matches(".close-icon")) {
        cp_warning.classList.add('closing-modal');
        rtt_warning.classList.add('closing-modal');
        overlay.classList.add('closing-overlay');
        cp_warning.classList.remove('opening-modal');
        rtt_warning.classList.remove('opening-modal');
      }
    });
    
    [].forEach.call(main_wrapper, function (el) {   
      el.addEventListener('click', function(event) {
        cp_warning.classList.add('closing-modal');
        rtt_warning.classList.add('closing-modal');
        overlay.classList.add('closing-overlay');
      })
    });
    
    if (cp_warning) {
      cp_warning.addEventListener('animationend', function() {
        if (this.classList.contains('closing-modal')) {
          closeModal(cp_warning, overlay, tooltip_boxes, notificationBox)
        }
      });
    }
  
    if (rtt_warning) {
      rtt_warning.addEventListener('animationend', function() {
        if (this.classList.contains('closing-modal')) {
          closeModal(rtt_warning, overlay, tooltip_boxes, notificationBox)
        }
      });
    }
  }
});

export function openModal(modal, overlay, tooltip_boxes, notificationBox) {
  modal.style.display = "flex";
  overlay.style.visibility = "visible";
  modal.classList.add('opening-modal');
  modal.classList.remove('closing-modal');
  overlay.classList.add('opening-overlay');
  overlay.classList.remove('closing-overlay');
  tooltip_boxes.forEach(tooltip_box => {
    tooltip_box.style.pointerEvents = "none";
  });
  notificationBox.style.pointerEvents = "none";
}

export function closeModal(modal, overlay, tooltip_boxes, notificationBox) {
  modal.style.display = "none";
  overlay.style.visibility = "hidden";
  tooltip_boxes.forEach(tooltip_box => {
    tooltip_box.style.pointerEvents = "auto";
  });
  notificationBox.style.pointerEvents = "auto";
}