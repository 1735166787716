const formatInputDate = require("./tabs").formatDate
const toggleCountersTooltipsOverlay = require("./overlay").toggleCountersTooltipsOverlay

window.addEventListener('DOMContentLoaded', () => {
  let searchInput = document.getElementById('search-input')
  const usersList = document.querySelectorAll('li.user')
  const usersListContainer = document.getElementById('users-list')
  const serchContainer = document.querySelector('.user-picker-container')
  const datePicker = document.querySelector('.datepicker');
  const dateInput = document.getElementById('datepicker');
  const tooltipBoxes = document.querySelectorAll('.tooltip-box');
  const overlay = document.querySelector('.overlay');
  const userFollowUpPage = document.getElementById('user-follow-up');
  const allButtonAbsenceYear = document.querySelectorAll('.header-card-absence');
  const countersGrid = document.getElementById('counters_grid');
  const june = "06"
  const september = "09"
  const reliquatYear = document.querySelector('.reliquat-year');
  const referenceYear = document.querySelector('.reference-year');
  const enCoursYear = document.querySelector('.en-cours-year');
  const cpReliquatCounter = document.getElementById('cp-reliquat-counter');
  const todayFakeDate = userFollowUpPage.dataset.todayDate
  const testingMode= userFollowUpPage.dataset.testingMode

  if(window.location.href.match(/user_follow_up/)) {
    if(datePicker && userFollowUpPage) {
      const url = window.location.href
      const urlObject = new URL(url);
      
      datePicker.style.display = 'block';
      dateInput.value = formatInputDate();
      dateInput.min = formatInputDate();
      dateParams = urlObject.searchParams.get("date")
      dateInput.value = dateParams
      changeReliquatYear()
      changeReferenceYear(dateInput.value.split("-")[1], dateInput.value.split("-")[0])
      changeenCoursYear()
      toggleShowCpReliquatCounter(dateInput.value.split("-")[1])
    }
  
    if (allButtonAbsenceYear) {
      allButtonAbsenceYear.forEach((buttonAbsenceYear) => {
        buttonAbsenceYear.addEventListener('click', function(event) {
          event.preventDefault()
          const buttonId = this.querySelectorAll('.absence-open-close')[0].id
          const bodyAbsenceYearId =`body-${buttonId}`
          const bodyAbsenceYear = document.getElementById(bodyAbsenceYearId);
          const arrowButton = document.getElementById(`arrow-button-${buttonId}`)
          const textButton = document.getElementById(`button-text-${buttonId}`)
          const yearAbsenceDate = document.getElementById(`absence-${buttonId}`)

          if (bodyAbsenceYear.className == "hide-years-absence") {
            bodyAbsenceYear.className = "body-card-absence-year"
            arrowButton.className =  "arrow-open"
            textButton.innerHTML = "Fermer" 
            yearAbsenceDate.className = "absence-year"
          } else {
            bodyAbsenceYear.className = "hide-years-absence"
            arrowButton.className =  "arrow-close"
            textButton.innerHTML = "Ouvrir"
            yearAbsenceDate.className = "absence-year absence-year-close"
      
          }
        });
      })
    }

    searchInput.addEventListener('input', (event) => {
      let inputValue = event.target.value.toLowerCase()
  
      openUsersOptionsList()
  
      usersList.forEach(user => {
        displayMatchingUser(user, inputValue)
  
        user.addEventListener('click', (e) => { 
          const userId = e.target.dataset.octopodId
          const date = dateInput ? dateInput.value : formatInputDate()
          const selectedDate = (testingMode && todayFakeDate !== "") ? todayFakeDate : date

          const url = '/admin/user_follow_up?id=' + userId + '&date=' + selectedDate
          
          searchInput.value = `${e.target.dataset.firstName} ${e.target.dataset.lastName} - ${e.target.dataset.nickname}`
          closeUsersOptionsList()
          window.location = url
        })
      })
  
      closeInputOptionsIfEmptyOrNoMatch(inputValue)

    })
    
    if(datePicker) {
      dateInput.addEventListener('change', (e) => {
        const userId = searchInput.dataset.selectedUserId
        const url = '/admin/user_follow_up?id=' + userId + '&date=' + dateInput.value
        window.location = url        
      })
    }

    toggleCountersTooltipsOverlay(tooltipBoxes, overlay)
    
    function closeInputOptionsIfEmptyOrNoMatch(inputValue) {
      const matchingList = document.querySelectorAll('li.user.match')
      
      if(!inputValue || matchingList.length <= 0) {
        closeUsersOptionsList()
      }
    }

    function displayMatchingUser(user, inputValue) {
      const userNickname = user.dataset.nickname.toLowerCase()
      const userFirstName = user.dataset.firstName.toLowerCase()
      const userLastName= user.dataset.lastName.toLowerCase()
      
      const isVisible = userNickname.includes(inputValue) ||
                        userFirstName.includes(inputValue) ||
                        userLastName.includes(inputValue)
      
      user.classList.toggle("hide", !isVisible)
      user.classList.toggle("match", isVisible)
    }

    function closeUsersOptionsList() {
      usersListContainer.style.display = 'none'
      serchContainer.classList.remove('active-input-background');
      searchInput.classList.remove('active-input');
    }

    function openUsersOptionsList() {
      usersListContainer.style.display = 'block'
      serchContainer.classList.add('active-input-background');
      searchInput.classList.add('active-input');
    }

    function toggleShowCpReliquatCounter(selected_month) {
      if((selected_month >= june && september > selected_month ) || countersGrid.classList.contains('postponed-reliquat')) {
        cpReliquatCounter.style.display = "block"
        countersGrid.classList.remove("ui", "three", "four", "column", "doubling", "stackable", "computer", "only", "tablet", "only", "grid" )
        countersGrid.classList.add("ui", "four", "column", "doubling", "stackable", "computer", "only", "tablet", "only", "grid")
      } else {
        cpReliquatCounter.style.display = "none"
        countersGrid.classList.remove("ui", "four", "three", "column", "doubling", "stackable", "computer", "only", "tablet", "only", "grid")
        countersGrid.classList.add("ui", "three", "column", "doubling", "stackable", "computer", "only", "tablet", "only", "grid" )
      }
    }

    function changeReliquatYear() {
      reliquatYear.innerHTML = dateInput.value.split("-")[0];
    }

    function changeReferenceYear(selected_month, selected_year) {
      if(selected_month >= june && selected_year == (new Date).getFullYear())  {
        referenceYear.innerHTML = (new Date).getFullYear()  + 1
      } else if(selected_month >= june) {
        referenceYear.innerHTML = parseInt(selected_year) + 1
      } else if(selected_year == (new Date).getFullYear() + 1) {
        referenceYear.innerHTML = (new Date).getFullYear() + 1
      } else if(selected_year == (new Date).getFullYear() + 2) {
        referenceYear.innerHTML = (new Date).getFullYear() + 2
      } else {
        referenceYear.innerHTML = (new Date).getFullYear()
      }
    }

    function changeenCoursYear() {
      enCoursYear.innerHTML = parseInt(referenceYear.innerHTML)  + 1
    }
  }
})