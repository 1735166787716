window.addEventListener('DOMContentLoaded', (event) => {
  const tooltipBoxes = document.querySelectorAll('.tooltip-box');
  const overlay = document.querySelector(".overlay");

  toggleCountersTooltipsOverlay(tooltipBoxes, overlay)
});

export function toggleCountersTooltipsOverlay(tooltipBoxes, overlay) {
  tooltipBoxes.forEach(tooltipBox => {
    tooltipBox.addEventListener('mouseover', function() {
      overlay.style.visibility = "visible";
    });
    tooltipBox.addEventListener('mouseout', function() {
      overlay.style.visibility = "hidden";
    });
  })
}