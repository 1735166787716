window.addEventListener('DOMContentLoaded', () => {
    const details_arrow = document.getElementById('details-arrow');
    const modal_close = document.getElementById('modal-close');
    const overlay = document.querySelector(".overlay");
    const details_modal = document.getElementById("details-modal");

    if (details_arrow) {
        details_arrow.addEventListener('click', function() {
            details_modal.style.display = "block";
            overlay.style.visibility = "visible"; 
        });
    }
    
    if (modal_close) {
        modal_close.addEventListener('click', function() {
            details_modal.style.display = "none";
            overlay.style.visibility = "hidden";
        });
    }
});