let modalOpened = require("./alert_modal").openModal

window.addEventListener('DOMContentLoaded', () => {
  const currentUserHolidaysPage = document.getElementById('current_user_holidays');
  const tabLinks = document.querySelectorAll('.tab');
  const balanceRttCounter = document.querySelector('#rtt .balance');
  const totalRttCounter = document.querySelector('#rtt .total');
  const countersWrapper = document.querySelector('.card-counter-wrapper');
  const cpReliquatCounter = document.getElementById('cp-reliquat-counter');
  const balanceCpReliquatCounter = document.querySelector('#cp-reliquat .balance');
  const totalCpReliquatCounter = document.querySelector('#cp-reliquat .total');
  const balanceCpReferenceCounter = document.querySelector('#cp-reference .balance');
  const totalCpReferenceCounter = document.querySelector('#cp-reference .total');
  const balanceCpEnCoursCounter = document.querySelector('#cp-en-cours .balance');
  const totalCpEnCoursCounter = document.querySelector('#cp-en-cours .total');
  const holidaysExplanationText = document.getElementById('holidays-explanation-text');
  const datePicker = document.querySelector('.datepicker');
  const dateInput = document.getElementById('datepicker');
  const rttYear = document.querySelector('.rtt-year');
  const reliquatYear = document.querySelector('.reliquat-year');
  const referenceYear = document.querySelector('.reference-year');
  const enCoursYear = document.querySelector('.en-cours-year');
  const overlay = document.querySelector('.overlay');
  const tooltip_boxes = document.querySelectorAll('.tooltip-box');
  const loader = document.querySelector('.loader');
  const cp_warning = document.getElementById("cp-warning");
  const rtt_warning = document.getElementById("rtt-warning");
  const notificationBox = document.getElementById('notification-box');
  const rttYearOnCounterMessage = document.querySelector('.tooltip .rtt-year')
  const yearlyRtt = document.querySelector('.tooltip .yearly-rtt')
  const monthlyRtt = document.querySelector('.tooltip .monthly-rtt')
  let monthlyRttValue = monthlyRtt?.innerHTML
  const cpReliquatPostponeDeadline = new Date(cpReliquatCounter?.dataset.deadline)
  const todayFakeDate = new Date(countersWrapper.dataset.todayDate)
  const testingMode= countersWrapper.dataset.testingMode
  let modalIsOpen = false
  const countersGrid = document.getElementById('counters_grid');
  const june = "06"
  const september = "09"

  if(currentUserHolidaysPage) {
    tabLinks[0]?.classList.add("active");
    const today = setTodayDateWhenTestingMode(dateInput.min.split("-")[1], testingMode, todayFakeDate)
    dateInput.value = formatDate(today);
    dateInput.min = formatDate(today);
    const selectedMonth = (today.getMonth() + 1).toString().padStart(2, "0")
    
    toggleShowCpReliquatCounter(selectedMonth)
    
    function changeRttYear() {
      rttYear.innerHTML = dateInput.value.split("-")[0];
      rttYearOnCounterMessage.innerHTML = dateInput.value.split("-")[0];
    }

    function changeReliquatYear() {
      reliquatYear.innerHTML = dateInput.value.split("-")[0];
    }
    
    function changeReferenceYear(selected_month, selected_year) {
      if(selected_month >= june && selected_year == (new Date).getFullYear())  {
        referenceYear.innerHTML = (new Date).getFullYear()  + 1
      } else if(selected_month >= june) {
        referenceYear.innerHTML = parseInt(selected_year) + 1
      } else if(selected_year == (new Date).getFullYear() + 1) {
        referenceYear.innerHTML = (new Date).getFullYear() + 1
      } else if(selected_year == (new Date).getFullYear() + 2) {
        referenceYear.innerHTML = (new Date).getFullYear() + 2
      } else {
        referenceYear.innerHTML = (new Date).getFullYear()
      }
    }

    function changeenCoursYear() {
      enCoursYear.innerHTML = parseInt(referenceYear.innerHTML)  + 1
    }

    async function getHolidays(url){
      try {
        startLoading();
        const response = await fetch(url, {
          method: 'GET',
        });
        const data = await response.json();
        return data;
      } catch(error) {
        console.log(error)
      }
    }

    function toggleShowCpReliquatCounter(selected_month) {
      const selectedDate = new Date(dateInput.value)

      if((selected_month >= june && september > selected_month ) || selectedDate <= cpReliquatPostponeDeadline) {
        cpReliquatCounter.style.display = "block"
        countersGrid.classList.remove("ui", "three", "four", "column", "doubling", "stackable", "computer", "only", "tablet", "only", "grid" )
        countersGrid.classList.add("ui", "four", "column", "doubling", "stackable", "computer", "only", "tablet", "only", "grid")
      } else {
        cpReliquatCounter.style.display = "none"
        countersGrid.classList.remove("ui", "four", "three", "column", "doubling", "stackable", "computer", "only", "tablet", "only", "grid")
        countersGrid.classList.add("ui", "three", "column", "doubling", "stackable", "computer", "only", "tablet", "only", "grid" )
      }
    }

    function insertDataIntoCounters(data) {
      balanceCpEnCoursCounter.innerHTML = data['conges_payes_en_cours']['balance'].toString() + ' ';
      totalCpEnCoursCounter.innerHTML = data['conges_payes_en_cours']['total_days'].toString() + ' ';
      balanceCpReferenceCounter.innerHTML = data['conges_payes_reference']['balance'].toString() + ' ';
      totalCpReferenceCounter.innerHTML = data['conges_payes_reference']['total_days'].toString() + ' ';
      balanceRttCounter.innerHTML = data['rtt']['balance'].toString() + ' ';
      totalRttCounter.innerHTML = data['rtt']['total_days'].toString() + ' ';
      if (balanceCpReliquatCounter && totalCpReliquatCounter) {
        balanceCpReliquatCounter.innerHTML = data['conges_payes_reliquat']['balance'].toString() + ' ';
        totalCpReliquatCounter.innerHTML = data['conges_payes_reliquat']['total_days'].toString() + ' ';
        if (parseFloat(balanceCpReliquatCounter.innerHTML) < 0) {
          document.getElementById('cp-reliquat').classList.add('warning');
        } else {
          document.getElementById('cp-reliquat').classList.remove('warning');
        }
      }

      if (parseFloat(balanceCpEnCoursCounter.innerHTML) < 0) {
        document.getElementById('cp-en-cours').classList.add('warning');
        modalOpened(cp_warning, overlay, tooltip_boxes, notificationBox);
        modalIsOpen = true

      } else {
        document.getElementById('cp-en-cours').classList.remove('warning');
      }

      if (parseFloat(balanceCpReferenceCounter.innerHTML) < 0) {
        document.getElementById('cp-reference').classList.add('warning');
      } else {
        document.getElementById('cp-reference').classList.remove('warning');
      }

      if (parseFloat(balanceRttCounter.innerHTML) < 0) {
        document.getElementById('rtt').classList.add('warning');
        modalOpened(rtt_warning, overlay, tooltip_boxes, notificationBox);
        modalIsOpen = true
      } else {
        document.getElementById('rtt').classList.remove('warning');
      }
    }

    tabLinks.forEach(tabLink => {
      tabLink.addEventListener('click', function() {
        if(tabLink === tabLinks[0]){
          const today = setTodayDateWhenTestingMode(new Date(), testingMode, todayFakeDate)
          // const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+ 1, 0);

          const selectedMonth = (today.getMonth() + 1).toString().padStart(2, "0")

          
          tabLinks[1].classList.remove("active");
          tabLink.classList.add("active");
          toggleShowCpReliquatCounter(selectedMonth)
          getHolidays('/get_current_month_holidays').then((currentMonthData) => {
            holidaysExplanationText.innerHTML = `Voici ton solde en <span>${currentMonthData['current_month']}</span>`;
            insertDataIntoCounters(currentMonthData);
            datePicker.style.display = 'none';
            rttYear.innerHTML = new Date().getFullYear();
            stopLoading();
          })
        } else {          
          tabLinks[0].classList.remove("active");
          tabLink.classList.add("active");
          const today = setTodayDateWhenTestingMode(dateInput.min.split("-")[1], testingMode, todayFakeDate)
          const selectedMonth = (today.getMonth() + 1).toString().padStart(2, "0")

          toggleShowCpReliquatCounter(selectedMonth)
          getHolidays('/get_holidays_forecast?date=' + dateInput.value).then((forecastData) => {
            holidaysExplanationText.innerHTML = "Rentre un jour prévisionnel pour visualiser tes futurs congés";
            insertDataIntoCounters(forecastData);
            datePicker.style.display = 'block';
            changeRttYear();
            changeReliquatYear()
            changeReferenceYear(dateInput.value.split("-")[1], dateInput.value.split("-")[0])
            changeenCoursYear()
            yearlyRtt.innerHTML = forecastData['rtt']['yearly'].toString() + ' ';
            monthlyRttValue = forecastData['rtt']['monthly'].toString() + ' ';
            stopLoading();
          })
        }
      })
    });

    dateInput.addEventListener('change', () => {
      changeRttYear();
      changeReliquatYear()
      changeReferenceYear(dateInput.value.split("-")[1], dateInput.value.split("-")[0])
      changeenCoursYear()
      toggleShowCpReliquatCounter(dateInput.value.split("-")[1])

      getHolidays('/get_holidays_forecast?date=' + dateInput.value).then((forecastData) => {
        insertDataIntoCounters(forecastData);
        yearlyRtt.innerHTML = forecastData['rtt']['yearly'].toString() + ' ';
        monthlyRttValue = forecastData['rtt']['monthly'].toString() + ' ';
        stopLoading();
      });
    })

    function startLoading() {
      tooltip_boxes.forEach(tooltip_box => {
        tooltip_box.style.pointerEvents = "none";
      });
      loader.style.display = 'flex';
      overlay.style.visibility = 'visible';
    }

    function stopLoading() {
      loader.style.display = 'none';
      if (!modalIsOpen) {
        overlay.style.visibility = 'hidden';
      } else {
        modalIsOpen = false
      }
      tooltip_boxes.forEach(tooltip_box => {
        tooltip_box.style.pointerEvents = "auto";
      });
    }
  }
})

export function setTodayDateWhenTestingMode(todayRealDate, testingMode, todayFakeDate) {
  return (testingMode && todayFakeDate !== null) ? todayFakeDate : todayRealDate
}


function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export function formatDate(date = new Date) {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');
}